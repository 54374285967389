import styled, { css, keyframes } from 'styled-components'
import { HashLink as Link } from 'react-router-hash-link'
import { device } from 'services/device'

const slideIn = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-65%); 
  }
`
const centerFlexNoDecor = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`
const mobileLinkStyles = css`
  font-weight: 100;
  font-size: ${(props) => props.theme.fontSize.lg};
  color: ${(props) => props.theme.color.white};
  ${centerFlexNoDecor};
  height: 25px;
  border-radius: 8px;
  padding: 2rem 0 2rem 0;
  justify-content: flex-start;
  color: ${(props) => props.theme.color.white} !important;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.color.gold} !important;
  }
`

//Mobile Nav Container
export const MobileNavContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  animation: ${slideIn} 400ms ease-in-out;
  top: 80px;
  width: 250px;
  transform: translateX(-65%);
  background: ${(props) => props.theme.color.blue};
  border: 1px ${(props) => props.theme.color.blue};
  border-radius: 25px 0 0 25px;
  padding: 1rem;
  overflow: hidden;
  transition: height 200ms ease;
`

//Mobile HyperLink
export const MobileNavItem = styled.a`
  ${mobileLinkStyles};
`

//Mobile Link
export const MobileNavLink = styled(Link)`
  ${mobileLinkStyles};
`

//Menu Button
export const IconButton = styled.a`
  ${centerFlexNoDecor};
  &:hover {
    background-color: transparent;
    cursor: pointer;
  }
`

//Container for settings button
export const NavButtonContainer = styled.li`
  display: none;
  justify-content: flex-end;
  @media ${device.deviceL} {
    width: 10%;
    ${centerFlexNoDecor};
  }
  @media ${device.deviceM} {
    width: 35%;
    ${centerFlexNoDecor};
    justify-content: flex-end;
  }
  @media ${device.deviceS} {
    width: 25%;
    ${centerFlexNoDecor};
    justify-content: flex-end;
  }
`
