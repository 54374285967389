import React from 'react'

// Styles
import { NavIconsContainer, NavIcon, FlagIcon } from './styles.js'

// Assets
import { flag_uk, flag_fi } from 'assets/images/index'
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons'

const NavIcons = (props) => (
  <NavIconsContainer>
    <NavIcon icon={faShoppingBasket} onClick={() => (window.location.href = 'https://holvi.com/shop/d2B3N0/')} />
    <FlagIcon alt='flag_fi' src={flag_fi} onClick={() => props.handleOpenModalFi()} />
    <FlagIcon alt='flag_uk' src={flag_uk} onClick={() => props.handleOpenModalEn()} />
  </NavIconsContainer>
)

export default NavIcons
