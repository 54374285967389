import React, { useState } from 'react'

// Styles
import { Container } from './styles'

// Components
import Timeline from '../../Common/Timeline/Timeline'
import HistoryPreview from './HistoryPreview'

const History = (props) => {
  const { title, image, text } = props
  const [showMore, setShowMore] = useState(false)

  return (
    <div>
      <HistoryPreview showMore={showMore} setShowMore={setShowMore} title={title} image={image} text={text} />
      {showMore && (
        <Container>
          <div>
            <Timeline language={'se'} skipFirstItem={true} />
          </div>
        </Container>
      )}
    </div>
  )
}

export default History
