import React from 'react'

// Assets
import { history } from 'assets/content/index'
import { backmansson, dirigent, mm140, pavilijonki } from 'assets/images/index'

// Components
import TimelineItem from './TimelineItem'

const Timeline = (props) => {
  const { language, skipFirstItem } = props

  const getImage = (img) =>
    img === 'backmansson'
      ? backmansson
      : img === 'dirigent'
      ? dirigent
      : img === 'pavilijonki'
      ? pavilijonki
      : img === 'mm140'
      ? mm140
      : null

  const historyToMap = skipFirstItem ? history[language].topics.slice(1) : history[language].topics

  return historyToMap.map((t, i) => {
    const showTextOnOpen = i === 0
    return (
      <div key={i}>
        <TimelineItem title={t.title} image={getImage(t.img)} text={t.text} showTextOnOpen={showTextOnOpen} />
      </div>
    )
  })
}

export default Timeline
