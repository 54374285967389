import React from 'react'

// Styles
import { FooterContainer, FooterRight, FooterLeft, Button } from './styles'

// Assets
import { logo_gold } from 'assets/images/index'

const CardFooter = (props) => (
  <FooterContainer>
    <FooterLeft>
      <Button onClick={() => props.setShow(!props.show)}>{props.show ? 'Läs mindre' : 'Läs mer'}</Button>
    </FooterLeft>
    <FooterRight>
      <img alt='logo_gold' style={{ height: '34px', width: 'auto' }} src={logo_gold}></img>
    </FooterRight>
  </FooterContainer>
)

export default CardFooter
