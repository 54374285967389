import styled, { keyframes } from 'styled-components'
import { device } from 'services/device'

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
`

// TIMELINE
export const TimeLineArea = styled.section`
  border-left: 3px solid ${(props) => (props.selected ? props.theme.color.gold : props.theme.color.blue)};
  padding: 0 1em 1.5em 2em;
  text-align: left;
  width: 100%;
  & div {
    animation: ${slideIn} 200ms ease-in-out;
    //overflow: hidden;
  }
  & h3 {
    position: relative;
    text-align: left;
    cursor: pointer;
    font-size: ${(props) => (props.selected ? '2em' : '1.2em')};
    color: ${(props) => props.theme.color.blue};
    transition: all 100ms ease-in-out;
    font-weight: 300;
    &:hover {
      color: ${(props) => props.theme.color.gold};
      transition: all 200ms ease;
    }
    &::before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.color.white};
      border: 3px solid ${(props) => (props.selected ? props.theme.color.gold : props.theme.color.blue)};
      position: absolute;
      top: ${(props) => (props.selected ? '10px' : '5px')};
      left: -43.1px;
    }
    @media ${device.deviceL} {
      width: 100%;
      font-size: ${(props) => (props.selected ? '1.5em' : '1em')};
    }
  }
`

export const TimelineItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.deviceL} {
    width: 100%;
    padding: 0;
    flex-direction: column;
  }
  @media ${device.deviceM} {
    & p {
      font-size: ${(props) => props.theme.fontSize.sm};
    }
  }
`

export const TimelineItemContainerInner = styled.div`
  width: 70%;
  display: flex;
  @media ${device.deviceL} {
    width: 100%;
    padding: 0;
  }
`

export const TimelineImageContainer = styled.div`
  width: 30%;
  margin: auto 0;
  & img {
    height: auto;
    width: 100%;
  }
  @media ${device.deviceL} {
    display: none;
  }
`
