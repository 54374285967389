import React from 'react'
import styled, { keyframes } from 'styled-components'
import { device } from 'services/device'

import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg'

const bounce = keyframes`
from{
transform: translateY(0);
}

to{
  transform: translateY(-7px);
}
`
const BackToTopContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0 2rem 1.5rem 0rem;
  & button {
    color: ${(props) => props.theme.color.blue};
    background: transparent;
    border: none;
    &:select {
      outline: 0;
      box-shadow: none;
    }
    &:focus {
      outline: 0;
      box-shadow: none;
    }

    & div {
      margin: 0 auto;
      background: transparent;
      border: 3px solid ${(props) => props.theme.color.gold};
      border-radius: 50%;
      width: 44px;
      height: 44px;
      & svg {
        fill: ${(props) => props.theme.color.gold};
      }
      &:hover {
        animation: ${bounce} 300ms ease-in-out;
        background: ${(props) => props.theme.color.blue};
        transform: translateY(-7px);
        transition: all 300ms ease-in-out;
      }
      @media ${device.deviceM} {
        width: 40px;
        height: 40px;
      }
    }
  }
  & p {
    font-size: ${(props) => props.theme.fontSize.sm};
    color: ${(props) => props.theme.color.blue};
    font-weight: 800;
  }
  @media ${device.deviceM} {
    margin: 0 1rem 1rem 1rem;
  }
`

const BackToTop = () => {
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  return (
    <BackToTopContainer>
      <button onClick={() => scrollToTop()}>
        <div>
          <ArrowUp />
        </div>
        {/*<p>Scrolla upp</p>*/}
      </button>
    </BackToTopContainer>
  )
}

export default BackToTop
