import styled from 'styled-components'
import { device } from 'services/device'
import { Subtitle } from '../../Common/Styles/Subtitle'

export { Subtitle as Subtitle }

export const TextContainer = styled.div`
  width: 70%;
  text-align: left;
  padding: 0 0 0 3em;
  flex-wrap: wrap;
  @media ${device.deviceL} {
    flex-direction: row;
    width: 100%;
    padding: 0;
  }
`

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media ${device.deviceL} {
    flex-direction: row;
  }
`

export const ImageContainer = styled.div`
  width: 30%;
  padding-top: 0.7em;
  margin: auto 0;
  & img {
    height: auto;
    width: 100%;
  }
  @media ${device.deviceL} {
    width: 100%;
    padding-bottom: 2em;
  }
`
