import React from 'react'

// Styles
import { Container, ImageContainer, TextContainer, Subtitle } from './styles'

const Director = (props) => {
  const { title, image, text } = props

  return (
    <Container>
      <ImageContainer>{image && <img alt='' src={image} />}</ImageContainer>
      <TextContainer>
        <Subtitle>{title}</Subtitle>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </TextContainer>
    </Container>
  )
}

export default Director
