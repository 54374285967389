import React from 'react'

// Styles
import { Page, Container, LogoContainer, Logo } from './styles'

// Components
import Wrapper from './Wrapper/Wrapper'

// Assets
import { logo_blue } from 'assets/images/index'
import { about } from 'assets/content/index'

const About = () => {
  const aboutRows = () => {
    return about.topics.map((topic, i) => {
      const left = i % 2 === 0
      return <Wrapper key={i} topic={topic} left={left} />
    })
  }

  return (
    <Page id='about'>
      <Container>
        <LogoContainer>
          <Logo src={logo_blue} />
        </LogoContainer>
        {aboutRows()}
      </Container>
    </Page>
  )
}

export default About
