import React, { useState } from 'react'

// Styles
import { Navbar, Nav } from './styles.js'

// Components
import { NavLogo, NavItems, NavIcons, InfoModal, MobileNav } from './index'

// Utils
import { useScroll } from '../../hooks/index'

const Navigation = () => {
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })
  const scrollHeight = useScroll(0)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const [historyLanguage, setHistoryLanguage] = useState('')

  const handleOpenModalEn = () => {
    setIsModalOpen(true)
    setHistoryLanguage('en')
  }

  const handleOpenModalFi = () => {
    setIsModalOpen(true)
    setHistoryLanguage('fi')
  }

  const handleLogoClick = () => {
    scrollToTop()
    setIsModalOpen(false)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Navbar top={!scrollHeight}>
      <Nav>
        {/* LOGO */}
        <NavLogo handleClick={handleLogoClick} />
        {/* MIDDLE NAV ITEMS */}
        <NavItems desktop={true} />
        {/* ICONS */}
        <NavIcons handleOpenModalEn={handleOpenModalEn} handleOpenModalFi={handleOpenModalFi} />
        {/* SHOPPING BASKET & FLAGS */}
        <InfoModal
          handleCloseModal={handleCloseModal}
          isModalOpen={isModalOpen}
          title={'Sällskapet MM'}
          historyLanguage={historyLanguage}
          showHistory={showHistory}
          setShowHistory={setShowHistory}
        />
        {/* MOBILE NAV */}
        <MobileNav handleOpenModalEn={handleOpenModalEn} handleOpenModalFi={handleOpenModalFi} />
      </Nav>
    </Navbar>
  )
}

export default Navigation
