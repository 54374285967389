import React from 'react'
import Modal from 'react-modal'

// Styles
import { CloseModalButton, CloseModalContainer, ModalTitle } from './styles'

// Components
import Timeline from '../../Common/Timeline/Timeline'

const InfoModal = (props) => {
  const { isModalOpen, historyLanguage, handleCloseModal, showHistory, setShowHistory, title } = props
  const customStyles = {
    content: {
      width: '80vw',
      marginTop: '4em',
      marginRight: 'auto',
      marginLeft: 'auto',
      padding: '1.5em',
      height: 'auto',
    },
    overlay: {
      backgroundColor: 'rgba(46, 49, 49, 0.8)',
    },
  }

  return (
    <Modal isOpen={isModalOpen} contentLabel='Example Modal' ariaHideApp={false} style={customStyles}>
      <CloseModalContainer>
        <CloseModalButton onClick={() => handleCloseModal()} />
      </CloseModalContainer>
      <ModalTitle>{title}</ModalTitle>
      <Timeline
        showHideButton={false}
        showTextOnOpen={true}
        language={historyLanguage}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
      />
    </Modal>
  )
}

export default InfoModal
