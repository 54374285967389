import React from 'react'
import { MediaIcon } from './styles'
import { faFacebookSquare, faSpotify, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons'
import constants from 'assets/content/constants.json'

const IconWrapper = (props) => (
  <a href={props.url} alt={props.description} target='_blank' rel='noopener noreferrer'>
    {props.children}
  </a>
)

export const youtubeIcon = () => (
  <IconWrapper url={constants.url.youtube} description={'youtube'}>
    <MediaIcon youtube icon={faYoutube} />
  </IconWrapper>
)

export const facebookIcon = () => (
  <IconWrapper url={constants.url.facebook} description={'facebook'}>
    <MediaIcon facebook icon={faFacebookSquare} />
  </IconWrapper>
)

export const spotifyIcon = () => (
  <IconWrapper url={constants.url.spotify} description={'spotify'}>
    <MediaIcon spotify icon={faSpotify} />
  </IconWrapper>
)

export const instagramIcon = () => (
  <IconWrapper url={constants.url.instagram} description={'instagram'}>
    <MediaIcon instagram icon={faInstagram} />
  </IconWrapper>
)
