import React from 'react'
import { Container, ContainerInner } from './styles'

// Components
import History from '../History/History'
import Director from '../Director/Director'

// Assets
import { backmansson, dirigent } from 'assets/images/index'

const Wrapper = (props) => {
  const { topic, left } = props
  const getImage = (imgString) => (imgString === 'backmansson' ? backmansson : imgString === 'dirigent' ? dirigent : null)

  return (
    <Container>
      <ContainerInner>
        {left ? (
          <Director title={topic.title} image={getImage(topic.img)} text={topic.text} />
        ) : (
          <History title={topic.title} image={getImage(topic.img)} text={topic.text} />
        )}
      </ContainerInner>
    </Container>
  )
}

export default Wrapper
