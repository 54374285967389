import React from 'react'

// Styles
import { Page, Wrapper, Container } from './styles'

// Components
import { Form, Sitemap, BottomRow } from './index'

const Footer = () => {
  return (
    <Page>
      <Wrapper>
        <Container>
          {/* FORM */}
          <Form />
          {/* SITEMAP */}
          <Sitemap />
        </Container>
        {/* SOME ICONS AND COPYRIGHT */}
        <BottomRow />
      </Wrapper>
    </Page>
  )
}

export default Footer
