/* eslint-disable react/display-name */
import React from 'react'

// Styles
import { Page, InfoText, Container, ChoirCardsContainer, Title } from './styles'

// Components
import ChoirCard from './ChoirCard/ChoirCard'

// Assets
import { audition } from 'assets/content/index'

const Audition = React.forwardRef((props, ref) => {
  const choirRows = () => audition.choirs.map((choir, i) => <ChoirCard key={i} title={choir.title} img={choir.img} content={choir.text} />)

  return (
    <Page>
      <Container>
        <Title ref={ref}>{audition.title}</Title>
        <InfoText>{audition.subtext}</InfoText>
        <ChoirCardsContainer>{choirRows()}</ChoirCardsContainer>
      </Container>
    </Page>
  )
})

export default Audition
