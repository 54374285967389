/* eslint-disable */
import styled from 'styled-components'

export const Page = styled.footer`
  background-color: ${(props) => props.theme.color.blue};
  color: ${(props) => props.theme.color.white};
  width: 100%;
`

export const Wrapper = styled.div`
  background: ${(props) => props.theme.color.blue};
  width: 75%;
  padding: 3rem 0 0 0;
  margin: 0 auto;
`

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
