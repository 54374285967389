import styled, { css } from 'styled-components'
import { device } from 'services/device'

const scrollPosition = css`
  /* This will position the anchor tag nicely */
  &:before {
    display: block;
    content: '';
    margin-top: -6rem;
    height: 6rem;
    visibility: hidden;
    pointer-events: none;
  }
`

export const Title = styled.h1`
  ${scrollPosition};
  font-size: ${(props) => props.theme.fontSize.huge};
  font-weight: 300;
  color: ${(props) => props.theme.color.gold};
  @media ${device.deviceL} {
    font-size: ${(props) => props.theme.fontSize.xxxl};
  }
  @media ${device.deviceS} {
    font-size: ${(props) => props.theme.fontSize.xxl};
  }
`

export const TitleLarge = styled.h1`
  font-size: ${(props) => props.theme.fontSize.special};
  color: ${(props) => props.theme.color.white};
  margin-bottom: 0.5em;
  @media ${device.deviceL} {
    font-size: ${(props) => props.theme.fontSize.xxxl};
  }
  @media ${device.deviceM} {
    font-size: ${(props) => props.theme.fontSize.xxxl};
  }
`
