import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

export { faAngleLeft as leftArrow, faAngleRight as rightArrow }

export const setPrevious = (activeIndex, setActiveIndex) => {
  if (activeIndex === 0) {
    setActiveIndex(3)
  } else if (activeIndex > 0) {
    setActiveIndex(activeIndex - 1)
  }
}

export const setNext = (activeIndex, setActiveIndex) => {
  if (activeIndex < 3) {
    setActiveIndex(activeIndex + 1)
  } else if (activeIndex >= 3) {
    setActiveIndex(0)
  }
}
