import React, { useState } from 'react'

// Styles
import { TimeLineArea, TimelineItemContainer, TimelineItemContainerInner, TimelineImageContainer } from './styles'

const TimelineItem = (props) => {
  const { title, text, showTextOnOpen, image } = props
  const [showText, setShowText] = useState(showTextOnOpen)
  return (
    <TimelineItemContainer>
      <TimelineItemContainerInner>
        <TimeLineArea selected={showText}>
          <h3 onClick={() => setShowText(!showText)}>{title}</h3>
          {showText && <div dangerouslySetInnerHTML={{ __html: text }} />}
        </TimeLineArea>
      </TimelineItemContainerInner>
      <TimelineImageContainer selected={showText}>{image && showText && <img alt='' src={image}></img>}</TimelineImageContainer>
    </TimelineItemContainer>
  )
}

export default TimelineItem
