import styled from 'styled-components'
import parallaxImg from 'assets/images/koren.webp'
import { device } from 'services/device'
import { Title } from '../Common/Styles/Title'

export { Title as Title }

export const Page = styled.div`
  background-image: url(${parallaxImg});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1em 0;
  text-align: center;
  @media ${device.deviceM} {
    background-image: none;
    background: ${(props) => props.theme.color.blue};
  }
`

export const Container = styled.div`
  width: 75%;
  margin: 0 auto;
  padding: 1rem 0 1rem 0;
`

export const InfoText = styled.p`
  font-weight: 400;
  color: ${(props) => props.theme.color.white};
`

export const ChoirCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0 2em 0;
`
