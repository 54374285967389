import React from 'react'
import { ThemeProvider } from 'styled-components'

const theme = {
  color: {
    gold: 'rgb(217,193,81)',
    white: 'rgb(255,255,255)',
    black: 'rgb(0, 0, 0)',
    blue: 'rgb(19, 18, 41)',
    youtube: 'rgb(255, 0, 0)',
    spotify: 'rgb(29, 185, 84)',
    facebook: 'rgb(66, 103, 178)',
    instagram: 'rgb(131, 58, 180)',
  },
  fontSize: {
    sm: '14px',
    md: '1em',
    mlg: '1.2em',
    lg: '1.5em',
    xl: '2em',
    xxl: '2.5em',
    xxxl: '3em',
    huge: '4em',
    special: '6em',
  },
  iconSize: {
    sm: '22px',
    md: '24px',
    lg: '32px',
    xl: '50px',
  },
}

const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>

export default Theme
