import React from 'react'

// Styles
import { BoardInfoContainer, BoardInfoImage, BoardInfoContainerInner, BoardInfoText, Subtitle } from './styles'

// Assets
import { boardInfo, constants } from 'assets/content/index'
import { funktionar } from 'assets/images/index'

const BoardInfo = () => (
  <BoardInfoContainer>
    <BoardInfoContainerInner>
      <BoardInfoImage>
        <img src={funktionar} alt='funktionar' />
      </BoardInfoImage>
      <BoardInfoText>
        <Subtitle>{constants.title.board}</Subtitle>
        <div dangerouslySetInnerHTML={{ __html: boardInfo.text }} />
      </BoardInfoText>
    </BoardInfoContainerInner>
  </BoardInfoContainer>
)

export default BoardInfo
