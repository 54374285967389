import React from 'react'
import { Dot } from 'react-animated-dots'

const Loader = ({ isLoader }) => {
  return (
    <span style={{ display: isLoader ? '' : 'none', fontSize: '40px' }}>
      <Dot>.</Dot>
      <Dot>.</Dot>
      <Dot>.</Dot>
    </span>
  )
}

export default Loader
