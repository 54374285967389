import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Styles
import { CarouselContainer, ContainerInner, ArrowContainer, Container, Page, Wrapper, Subtitle } from './styles'

// Components
import CarouselButtons from './CarouselButtons'
import CarouselHeader from './CarouselHeader'

// Utils
import { useSwipeable } from 'react-swipeable'

// Assets
import { form as formContent } from 'assets/content/index'
import { setNext, setPrevious, leftArrow, rightArrow } from './index'

const Carousel = () => {
  let [index, setIndex] = useState(0)
  const [stopped, setStopped] = useState(false)
  const [slideOut, setSlideOut] = useState(false)
  const [activeButton, setActiveButton] = useState(0)
  const [activeIndex, setActiveIndex] = useState(activeButton)

  const carouselTimeout = 280

  const changeTopic = (index) => {
    if (slideOut) {
      setSlideOut(false)
      setTimeout(() => {
        setIndex(index)
        setActiveButton(index)
        setSlideOut(true)
      }, carouselTimeout)
    } else {
      setSlideOut(true)
      setTimeout(() => {
        setIndex(index)
        setActiveButton(index)
        setSlideOut(false)
      }, carouselTimeout)
    }
    setStopped(true)
  }

  const useCarousel = () => {
    const changeSpeed = 280
    const switchInterval = 10 * 1000
    const readTime = 10 * 1900

    return useEffect(() => {
      const interval = setInterval(() => {
        if (!stopped) {
          if (index === formContent.topics.length - 1) {
            setSlideOut(true)
            setTimeout(() => {
              setIndex(0)
              setActiveButton(0)
              setSlideOut(false)
            }, changeSpeed)
          } else {
            setSlideOut(true)
            setTimeout(() => {
              setIndex((index = index + 1))
              setActiveButton(index)
              setSlideOut(false)
            }, changeSpeed)
          }
        } else {
          setTimeout(() => {
            setStopped(false)
          }, readTime)
        }
      }, switchInterval)
      return () => clearInterval(interval)
    }, [index, stopped])
  }

  const arrowStyle = {
    cursor: 'pointer',
    fontSize: '40px',
  }

  const arrowParams = [activeIndex, setActiveIndex]

  const arrowRight = <FontAwesomeIcon icon={rightArrow} style={arrowStyle} onClick={() => setNext(...arrowParams)} />
  const arrowLeft = <FontAwesomeIcon icon={leftArrow} style={arrowStyle} onClick={() => setPrevious(...arrowParams)} />

  const useChangeTopic = () => {
    useEffect(() => {
      changeTopic(activeIndex)
    }, [activeIndex])
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => setNext(...arrowParams),
    onSwipedRight: () => setPrevious(...arrowParams),
  })

  useChangeTopic()
  useCarousel()

  return (
    <Page id='forminfo'>
      <CarouselHeader />
      <Wrapper>
        <Container>
          <ArrowContainer right>{arrowLeft}</ArrowContainer>
          <ContainerInner {...handlers}>
            <CarouselContainer fading={slideOut}>
              <Subtitle>{formContent.topics[index].title}</Subtitle>
              <div
                dangerouslySetInnerHTML={{
                  __html: formContent.topics[index].content,
                }}
              />
            </CarouselContainer>
            <CarouselButtons activeButton={activeButton} changeTopic={changeTopic} content={formContent}></CarouselButtons>
          </ContainerInner>
          <ArrowContainer>{arrowRight}</ArrowContainer>
        </Container>
      </Wrapper>
    </Page>
  )
}

export default Carousel
