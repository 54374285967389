import styled from 'styled-components'
import { TitleLarge } from 'components/Common/Styles/Title'
import { ButtonSecondary } from 'components/Common/Styles/Button'
import { SubtitleLarge } from 'components/Common/Styles/Subtitle'

export { TitleLarge as Title, ButtonSecondary as Button, SubtitleLarge as Subtitle }

export const Page = styled.div`
  height: 100vh;
  background: ${(props) => `url(${props.background}) no-repeat center center`};
  background-size: cover;
  overflow-x: hidden;
  text-align: center;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 0 auto;
`
