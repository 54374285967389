import React from 'react'

// Styles
import { MobileNavContainer, MobileNavItem } from './styles.js'

// Components
import NavItems from '../NavItems'
import MobileNavToggler from './MobileNavToggler'

const MobileNav = (props) => {
  const { handleOpenModalEn, handleOpenModalFi } = props
  return (
    <MobileNavToggler>
      <MobileNavContainer>
        <NavItems desktop={false} />
        <MobileNavItem onClick={() => (window.location.href = 'https://holvi.com/shop/d2B3N0/')}>Butik</MobileNavItem>
        <MobileNavItem onClick={() => handleOpenModalFi()}>Suomeksi</MobileNavItem>
        <MobileNavItem onClick={() => handleOpenModalEn()}>In english</MobileNavItem>
      </MobileNavContainer>
    </MobileNavToggler>
  )
}

export default MobileNav
