import styled from 'styled-components'
import { device } from 'services/device'

export const Subtitle = styled.h2`
  font-size: ${(props) => props.theme.fontSize.xxxl};
  font-weight: 400;
  @media ${device.deviceM} {
    font-size: ${(props) => props.theme.fontSize.xl};
  }
`

export const SubtitleSmall = styled.h3`
  font-size: ${(props) => props.theme.fontSize.xl};
  font-weight: 300;
  color: ${(props) => (props.gold ? props.theme.color.gold : props.theme.color.black)};
  @media ${device.deviceS} {
    font-size: ${(props) => props.theme.fontSize.lg};
  }
`

export const SubtitleLarge = styled.span`
  font-size: ${(props) => props.theme.fontSize.huge};
  color: ${(props) => props.theme.color.gold};
  @media ${device.deviceL} {
    font-size: ${(props) => props.theme.fontSize.xxxl};
  }
  @media ${device.deviceM} {
    font-size: ${(props) => props.theme.fontSize.xxl};
  }
`
