import styled from 'styled-components'
import { device } from 'services/device'

export const CloseModalContainer = styled.div`
  height: 40px;
`
export const CloseModalButton = styled.a`
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.8;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 4px;
    background-color: #333;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

export const ModalTitle = styled.h1`
  font-size: ${(props) => props.theme.fontSize.xxxl};
  text-align: center;
  padding-bottom: 1em;
  @media ${device.deviceM} {
    font-size: ${(props) => props.theme.fontSize.lg};
  }
`
