import styled from 'styled-components'
import { device } from 'services/device'
import { SubtitleSmall } from '../Styles/Subtitle'

export { SubtitleSmall as Subtitle }

/* BOARD INFO STUFF */
export const BoardInfoContainer = styled.div`
  padding: 2em 0;
  width: 100%;
  background-color: ${(props) => props.theme.color.white};
`

export const BoardInfoContainerInner = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  color: ${(props) => props.theme.color.black};
  @media ${device.deviceL} {
    width: 85%;
  }
  @media ${device.deviceM} {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`

export const BoardInfoImage = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 2em;
  & img {
    width: 100%;
    height: 100%;
  }
  @media ${device.deviceL} {
    width: 120px;
    height: 120px;
  }
  @media ${device.deviceM} {
    margin: 0 0 1em 0;
  }
`
export const BoardInfoText = styled.div`
  margin: auto 0;
  & p {
    & a {
      color: ${(props) => props.theme.color.gold};
      font-weight: 800;
      text-decoration: none;
    }
  }
`
