import styled from 'styled-components'
import { device } from 'services/device'
import { SubtitleSmall } from '../../Common/Styles/Subtitle'

export { SubtitleSmall as Subtitle }

export const Container = styled.div`
  display: block;
  overflow: show;
  display: flex;
  width: 40%;
  @media ${device.deviceL} {
    width: 100%;
  }
`

export const ListItem = styled.div`
  & p {
    font-weight: 600;
    font-size: 16px;
    & a {
      text-decoration: none;
    }
  }
  &:hover {
    color: ${(props) => props.theme.color.gold};
  }
`

export const ListUrl = styled(ListItem)`
  & span {
    font-weight: 300;
    font-size: 16px;
    color: ${(props) => props.theme.color.gold};
  }
  &:hover {
    & p {
      color: ${(props) => props.theme.color.white};
    }
  }
`
