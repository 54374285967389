import React from 'react'
import ReactDOM from 'react-dom'
import App from 'App'
import Theme from 'services/Theme'
import 'index.scss'

ReactDOM.render(
  <Theme>
    <App />
  </Theme>,
  document.getElementById('root')
)
