/* eslint-disable */
import React from 'react'
import styled from 'styled-components'
import { device } from 'services/device'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const SomeIcon = styled(FontAwesomeIcon)`
  font-size: ${(props) => props.theme.iconSize.md};
`

export const SomeIconContainer = styled.div`
  &:hover ${SomeIcon} {
    color: ${(props) => props.theme.color.gold};
  }
  &:hover {
    cursor: pointer;
  }
`

export const BottomContainer = styled.div`
  padding: 1.5em 0 2.5em 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & div {
    display: flex;
    @media ${device.deviceM} {
      margin-right: 1.2em;
      padding-bottom: 1em;
    }
  }
  @media ${device.deviceM} {
    &div {
      flex-direction: column;
      &div {
        flex-direction: column;
      }
    }
  }
`

export const SomeIconText = styled.span`
  color: ${(props) => props.theme.color.white};
  line-height: 24px;
  margin-left: 10px;
  @media ${device.deviceM} {
    display: none;
  }
`

export const CopyRight = styled.span`
  color: ${(props) => props.theme.color.gold} !important;
  @media ${device.deviceM} {
    display: block;
  }
`
