import styled, { css } from 'styled-components'
import { device } from 'services/device'

const scrollPosition = css`
  &::before {
    display: block;
    content: '';
    margin-top: -4rem;
    height: 4rem;
    visibility: hidden;
    pointer-events: none;
  }
`

// MAIN PAGE STUFF
export const LogoContainer = styled.div`
  padding-bottom: 4em;
`

export const Logo = styled.img`
  height: 250px;
  width: auto;
  @media ${device.deviceM} {
    height: 200px;
  }
`

export const Page = styled.div`
  ${scrollPosition};
  background: ${(props) => props.theme.color.white};
  width: 100%;
  padding: 2em 0;
`

export const Container = styled.div`
  background: ${(props) => props.theme.color.white};
  width: 75%;
  padding: 1rem 0 1rem;
  margin: 0 auto;
  text-align: center;
  align-items: flex-end;
`

// ABOUT CONTAINER
export const AboutContainerStyle = styled.div`
  height: auto;
  display: flex;
  margin-bottom: 3em;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media ${device.deviceL} {
    flex-direction: row;
  }
`

export const AboutContainerStyleInner = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 1em;
  flex-wrap: wrap;
  @media ${device.deviceL} {
    flex-direction: row;
  }
`
