import React, { useState, useEffect } from 'react'
import emailjs from 'emailjs-com'

// Styles
import { Input, TextArea, NameAndEmailContainer, ButtonContainer, InfoTextContainer, FormContainer, Subtitle, Button } from './styles'

// Assets
import { constants, form as formContent } from 'assets/content/index'
import { emailJsServiceId, emailJsTemplateId, emailJsUserId, emailTo } from './index'

// Components
import Loader from './Loader'
import Dropdown from './Dropdown'

// Utils
import { useField } from '../../../hooks/index'

const Form = () => {
  const [selectedOption, setSelectedOption] = useState(0)
  const [info, setInfo] = useState('')
  const [error, setError] = useState(false)
  const [isLoader, setIsLoader] = useState(false)

  const emailParams = {
    name: useField('text'),
    email: useField('email'),
    message: useField('text'),
    email_to: emailTo,
  }

  const sendEmail = async (event) => {
    event.preventDefault()

    const templateParams = {
      topic: getTopicFromSelectedOption(),
      from_name: emailParams.name.params.value,
      from_email: emailParams.email.params.value,
      to_email: emailParams.email_to,
      message: emailParams.message.params.value,
    }

    if (parseInt(selectedOption) === 0) setInfoMessage(constants.error.missingTopic, true)
    else if (!emailParams.name.params.value) setInfoMessage(constants.error.missingName, true)
    else if (!emailParams.email.params.value) setInfoMessage(constants.error.missingEmail, true)
    else {
      setInfoMessage(constants.notification.sendingEmail, false)
      setIsLoader(true)
      await emailjs.send(emailJsServiceId, emailJsTemplateId, templateParams, emailJsUserId).then(
        (result) => {
          setIsLoader(false)
          setInfoMessage(constants.notification.emailSent, false)
          console.log('Email sent: ', result.text)
        },
        (error) => {
          setInfoMessage(constants.error.emailError, true)
          setIsLoader(false)
          console.log('Couldnt send email: ', error.text)
        }
      )
      emailParams.name.reset()
      emailParams.email.reset()
      emailParams.message.reset()
    }
  }

  const useLoader = () => {
    useEffect(() => {
      if (info && !isLoader) {
        const interval = setInterval(() => {
          setInfo('')
        }, 6500)
        return () => clearInterval(interval)
      }
    }, [info])
  }

  const getTopicFromSelectedOption = () => formContent.dropDownValues.filter((topic) => topic.value === parseInt(selectedOption))[0].label

  const setInfoMessage = (message, error) => {
    setInfo(message)
    setError(error)
  }

  const isEmptyFields = (values) => {
    if (values) {
      for (const value of values) {
        if (!value) return true
      }
    } else return false
  }

  useLoader()

  return (
    <FormContainer>
      <div>
        <Subtitle gold id='footer'>
          {constants.title.sendEmail}
        </Subtitle>
        <form id='contact-form' onSubmit={sendEmail}>
          {/* NAME AND EMAIL */}
          <NameAndEmailContainer>
            <div>
              <Input {...emailParams.name.params} placeholder={constants.field.name} />
            </div>
            <div>
              <Input {...emailParams.email.params} placeholder={constants.field.email} />
            </div>
          </NameAndEmailContainer>
          {/* TOPIC DROPDOWN */}
          <Dropdown selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
          {/* TEXT AREA */}
          <TextArea {...emailParams.message.params} placeholder={constants.field.message} />
          {/* FORM BUTTON */}
          <ButtonContainer>
            <Button alt={+isEmptyFields([emailParams.name.params.value, emailParams.email.params.value])} type='submit'>
              {constants.button.send}
            </Button>
            <InfoTextContainer error={error}>
              {info}
              <Loader isLoader={isLoader} />
            </InfoTextContainer>
          </ButtonContainer>
        </form>
      </div>
    </FormContainer>
  )
}

export default Form
