import React from 'react'

// Styles
import { BottomContainer, CopyRight, SomeIconText, SomeIcon, SomeIconContainer } from './styles'

// Assets
import { someIcons, constants } from './index'

const BottomRow = () => {
  const iconsAmount = 4

  const someIconRow = () =>
    someIcons.map((icon, i) => (
      <SomeIconContainer key={i} onClick={() => window.location.assign(icon.url)}>
        <SomeIcon icon={icon.icon} />
        <SomeIconText>{icon.text}</SomeIconText>
        {i <= iconsAmount - 2 && <span style={{ margin: '0 1em' }} />}
      </SomeIconContainer>
    ))

  return (
    <BottomContainer>
      <div>{someIconRow()}</div>
      <div>
        <CopyRight>
          <span>&copy;</span> {constants.text.copyright}
        </CopyRight>
      </div>
    </BottomContainer>
  )
}

export default BottomRow
