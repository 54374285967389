import React from 'react'

// Components
import { Hero, Media, About, Audition, ScrollToTopOnMount, BoardInfo, Carousel } from './index'

const Home = () => {
  const scrollRef = React.createRef()
  return (
    <div>
      <ScrollToTopOnMount />
      <Hero scrollPosition={scrollRef} />
      <Media />
      <About />
      <Audition ref={scrollRef} />
      <Carousel />
      <BoardInfo />
    </div>
  )
}
export default Home
