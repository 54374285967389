import React from 'react'

// Styles
import { LogoContainer, Logo } from './styles.js'

// Assets
import { logo_gold } from 'assets/images/index'

const NavLogo = (props) => (
  <LogoContainer onClick={() => props.handleClick()}>
    <Logo src={logo_gold} />
  </LogoContainer>
)

export default NavLogo
