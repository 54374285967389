import React from 'react'

// Styles
import { Container, Page, Subtitle, Button, Title } from './styles'

// Assets
import { mm } from 'assets/images/index'
import { constants } from 'assets/content/index'

const Hero = (props) => {
  const scrollToRef = () => props.scrollPosition.current.scrollIntoView({ behavior: 'smooth' })
  const openFacebookEvent = () => (window.location.href = constants.url.facebookEvents)
  return (
    <Page background={mm}>
      <Container>
        <div>
          <Subtitle>{constants.title.company}</Subtitle>
          <Title>{constants.title.mm}</Title>
          <Button onClick={() => scrollToRef()}>{constants.button.audition}</Button>
          <Button alt={+true} onClick={() => openFacebookEvent()}>
            {constants.button.concerts}
          </Button>
        </div>
      </Container>
    </Page>
  )
}

export default Hero
