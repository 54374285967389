import React, { useState } from 'react'

// Styles
import { Container, ContainerInner, Content, Body, ImageContainer } from './styles'

// Components
import CardFooter from './CardFooter'

// Assets
import { sek, ork, kak } from 'assets/images/index'

const ChoirCard = ({ title, content, img }) => {
  const [show, setShow] = useState(false)
  const getImage = (imgString) => (imgString === 'sek' ? sek : imgString === 'ork' ? ork : imgString === 'kak' ? kak : null)

  return (
    <Container>
      <ContainerInner>
        <ImageContainer>
          <img alt='' src={getImage(img)} />
        </ImageContainer>
        <Body>
          <h3>{title}</h3>
          {show && <Content dangerouslySetInnerHTML={{ __html: content }} />}
          <CardFooter show={show} setShow={setShow} />
        </Body>
      </ContainerInner>
    </Container>
  )
}
export default ChoirCard
