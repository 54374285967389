import React, { useRef } from 'react'
import Burger from '@animated-burgers/burger-rotate'
import '@animated-burgers/burger-rotate/dist/styles.css'

// Styles
import { NavButtonContainer, IconButton } from './styles.js'

// Utils
import { useCloseOnClickOutside } from '../../../hooks/index'

const DropdownToggler = (props) => {
  const dropdownRef = useRef(null)
  const [open, setOpen] = useCloseOnClickOutside(dropdownRef, false)

  return (
    <NavButtonContainer>
      <div ref={dropdownRef}>
        <IconButton onClick={setOpen}>{<Burger isOpen={open} />}</IconButton>
        {open && props.children}
      </div>
    </NavButtonContainer>
  )
}

export default DropdownToggler
