/* eslint-disable */
import styled from 'styled-components'
import { device } from 'services/device'
import { SubtitleSmall } from '../../Common/Styles/Subtitle'
import { ButtonAlt } from '../../Common/Styles/Button'

export { ButtonAlt as Button, SubtitleSmall as Subtitle }

export const FormContainer = styled.div`
  display: flex;
  width: 60%;
  padding: 0 4em 0 0;
  & div {
    width: 100%;
  }
  @media ${device.deviceL} {
    width: 100%;
    padding: 0 0 2em 0;
  }
`

export const NameAndEmailContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 1.5em;
  & div {
    width: 49%;
  }
`

export const Select = styled.select`
  width: 100%;
  height: 35px;
  margin-bottom: 1em;
  font-size: ${(props) => props.theme.fontSize.sm};
  background: ${(props) => props.theme.color.white};
  border: 2px solid ${(props) => props.theme.color.white};
  border-radius: 3px;
  &::placeholder {
    font-size: ${(props) => props.theme.fontSize.sm};
    color: gray;
  }
  &:focus {
    outline: none;
  }
`

export const Input = styled.input`
  width: 100%;
  color: ${(props) => props.theme.color.black};
  margin-bottom: 1rem;
  font-size: ${(props) => props.theme.fontSize.sm};
  background: ${(props) => props.theme.color.white};
  border: 2px solid ${(props) => props.theme.color.white};
  height: 35px;
  border-radius: 3px;
  &::placeholder {
    font-size: ${(props) => props.theme.fontSize.sm};
    color: ${(props) => props.theme.color.black};
  }
  &:focus {
    outline: none;
  }
`

export const TextArea = styled.textarea`
  font-size: ${(props) => props.theme.fontSize.sm};
  height: 10em;
  color: ${(props) => props.theme.color.black};
  width: 100%;
  background: ${(props) => props.theme.color.white};
  margin-bottom: 12px;
  resize: none;
  border: 2px solid ${(props) => props.theme.color.white};
  border-radius: 3px;
  &::placeholder {
    font-size: ${(props) => props.theme.fontSize.sm};
    color: ${(props) => props.theme.color.black};
  }
  &:focus {
    outline: none;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  max-height: 50px;
`

export const InfoTextContainer = styled.div`
  color: ${(props) => (props.error ? 'red' : props.theme.color.gold)};
  font-weight: 400;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  line-height: 20px;
`
