import React from 'react'

// Styles
import { CarouselHeaderContainer, SubtitleAlt } from './styles'

const CarouselHeader = () => (
  <CarouselHeaderContainer>
    <div>
      <SubtitleAlt>Kontakta MM</SubtitleAlt>
    </div>
  </CarouselHeaderContainer>
)

export default CarouselHeader
