/* eslint-disable */
import styled, { keyframes, css } from 'styled-components'
import { device } from 'services/device'
import { SubtitleSmall, Subtitle } from '../Styles/Subtitle'

export { SubtitleSmall as Subtitle }

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0); 
  }
`

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%); 
  }
`

const scrollPosition = css`
  /* This will position the anchor tag nicely */
  &::before {
    display: block;
    content: '';
    margin-top: -3rem;
    height: 3rem;
    visibility: hidden;
    pointer-events: none;
  }
`

export const Page = styled.div`
  background: ${(props) => props.theme.color.white};
  width: 100%;
  color: ${(props) => props.theme.color.blue};
  height: auto;
  ${scrollPosition};
`

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`

export const Container = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  @media ${device.deviceL} {
    width: 100%;
  }
`

export const ContainerInner = styled.div`
  width: 100%;
`

export const CarouselContainer = styled.div`
  overflow: hidden;
  animation: ${(props) => (props.fading ? slideOut : slideIn)} 350ms ease-in-out;
  min-height: 280px;
  min-width: 86%;
  @media ${device.deviceL} {
    min-height: 300px;
    padding: 0 4em;
  }
  @media ${device.deviceM} {
    min-height: 420px;
    padding: 0 4em;
  }
  @media ${device.deviceS} {
    min-height: 440px;
    padding: 0 1.6em;
  }
`

export const ArrowContainer = styled.div`
  margin: auto 0;
  padding: ${(props) => (props.right ? '0 5% 0 0' : '0 0 0 5%')};
  &:hover {
    color: ${(props) => props.theme.color.gold};
    cursor: pointer;
  }
  @media ${device.deviceL} {
    display: none;
  }
`

export const CarouselButtonContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
`
export const CarouselText = styled.span`
  color: ${(props) => (props.active ? `${props.theme.color.gold}` : `${props.theme.color.black}`)};
  cursor: pointer;
  font-weight: ${(props) => (props.active ? '600' : `500`)};
`

/* HEADER STUFF */
export const CarouselHeaderContainer = styled.div`
  padding: 2em 0;
  & div {
    text-align: center;
    height: auto;
    width: 100%;
    border-top: 3px solid ${(props) => props.theme.color.gold};
  }
`

export const SubtitleAlt = styled(Subtitle)`
  width: 330px;
  margin: -38px auto;
  background: white;
  @media ${device.deviceM} {
    width: 230px;
    margin: -26px auto;
  }
`

/* BOARD INFO STUFF */
export const BoardInfoContainer = styled.div`
  padding: 1em 0;
  width: 100%;
  background-color: ${(props) => props.theme.color.white};
`

export const BoardInfoContainerInner = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  @media ${device.deviceL} {
    width: 85%;
  }
  @media ${device.deviceM} {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`

export const BoardInfoImage = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 2em;
  & img {
    width: 100%;
    height: 100%;
  }
  @media ${device.deviceL} {
    width: 120px;
    height: 120px;
  }
  @media ${device.deviceM} {
    margin: 0 0 1em 0;
  }
`
export const BoardInfoText = styled.div`
  margin: auto 0;
  & p {
    & a {
      color: ${(props) => props.theme.color.gold};
      font-weight: 800;
      text-decoration: none;
    }
  }
`
