import React from 'react'

// Styles
import { Wrapper, Page, Container, Item, HeaderContainer, IconText, TitleAlt } from './styles'

// Assets
import { youtubeIcon, facebookIcon, spotifyIcon, instagramIcon } from './index'
import { constants, media } from 'assets/content/index'

const Media = () => {
  const drawIcon = (topic) =>
    topic === 'Youtube' ? youtubeIcon() : topic === 'Spotify' ? spotifyIcon() : topic === 'Facebook' ? facebookIcon() : instagramIcon()

  const mediaRow = () =>
    media.icons.map((topic) => (
      <div key={topic}>
        <Item youtube>
          <span>{drawIcon(topic)}</span>
        </Item>
        <IconText>{topic}</IconText>
      </div>
    ))

  return (
    <Page>
      <Wrapper>
        <Container>
          <HeaderContainer>
            <TitleAlt>{constants.title.mediaAndMusic}</TitleAlt>
            <div dangerouslySetInnerHTML={{ __html: media.text }} />
          </HeaderContainer>
        </Container>
        <Container>{mediaRow()}</Container>
      </Wrapper>
    </Page>
  )
}

export default Media
