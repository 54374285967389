import styled from 'styled-components'
import { device } from 'services/device'

export const Container = styled.div`
  height: auto;
  display: flex;
  margin-bottom: 3em;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media ${device.deviceL} {
    flex-direction: row;
  }
`

export const ContainerInner = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 1em;
  flex-wrap: wrap;
  @media ${device.deviceL} {
    flex-direction: row;
  }
`
