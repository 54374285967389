import styled from 'styled-components'
import { device } from 'services/device'

export const Button = styled.button`
  border: 2px solid ${(props) => props.theme.color.blue};
  font-size: ${(props) => props.theme.fontSize.sm};
  background: transparent;
  color: ${(props) => props.theme.color.blue};
  border-radius: 3px;
  font-weight: 600;
  min-width: 120px;
  min-height: 40px;
  &:hover {
    color: ${(props) => props.theme.color.black};
    background: ${(props) => props.theme.color.gold};
  }
  &:active {
    outline: none;
  }
`

export const ButtonSecondary = styled.button`
  border: 2px solid ${(props) => props.theme.color.gold};
  font-size: ${(props) => props.theme.fontSize.md};
  background: ${(props) => (props.alt ? props.theme.color.gold : 'transparent')};
  color: ${(props) => (props.alt ? props.theme.color.black : props.theme.color.gold)};
  border-radius: 2px;
  font-weight: 400;
  min-width: 130px;
  min-height: 42px;
  margin: 0 2em;
  &:hover {
    color: ${(props) => (props.alt ? props.theme.color.gold : props.theme.color.black)};
    background: ${(props) => (props.alt ? 'transparent' : props.theme.color.gold)};
    transform: scale(0.98);
    transition: all 400ms ease;
  }
  &:active {
    outline: none;
  }
  @media ${device.deviceM} {
    font-size: ${(props) => props.theme.fontSize.sm};
    min-width: 110px;
    min-height: 35px;
    margin: 0 1em;
  }
`

export const ButtonAlt = styled.button`
  border: 2px solid ${(props) => props.theme.color.white};
  font-size: ${(props) => props.theme.fontSize.sm};
  background: transparent;
  color: ${(props) => props.theme.color.white};
  border-radius: 3px;
  font-weight: 400;
  min-width: 167px;
  min-height: 40px;
  margin-right: 10px;
  &:hover {
    color: ${(props) => (props.alt ? props.theme.color.white : props.theme.color.black)};
    background: ${(props) => (props.alt ? 'transparent' : props.theme.color.gold)};
    transform: ${(props) => (props.alt ? 'scale(1)' : 'scale(0.98)')};
    transition: all 400ms ease;
  }
  &:active {
    outline: none;
  }
  @media ${device.deviceL} {
    font-size: ${(props) => props.theme.fontSize.sm};
    min-width: 110px;
    min-height: 35px;
  }
`
