import React from 'react'

// Styles
import { NavHyperlink, NavLink, NavContainerDesktop } from './styles.js'
import { MobileNavLink, MobileNavItem } from './MobileNav/styles.js'

// Assets
import { navigation } from 'assets/content/index'

const NavItems = (props) => {
  const { desktop } = props
  const navRows = () =>
    navigation.items.map((item) => (
      <NavContainerDesktop key={item.text}>
        {item.hyperlink ? (
          <NavHyperlink target='_blank' href={item.location}>
            {item.text}
          </NavHyperlink>
        ) : (
          <NavLink smooth to={item.location}>
            {item.text}
          </NavLink>
        )}
      </NavContainerDesktop>
    ))

  const mobileNavRows = () =>
    navigation.items.map((item) => (
      <div key={item.text}>
        {item.hyperlink ? (
          <MobileNavItem target='_blank' href={item.location}>
            {item.text}
          </MobileNavItem>
        ) : (
          <MobileNavLink smooth to={item.location}>
            {item.text}
          </MobileNavLink>
        )}
      </div>
    ))
  return desktop ? navRows() : mobileNavRows()
}

export default NavItems
