import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'
import { HashLink as Link } from 'react-router-hash-link'
import { device } from 'services/device'

const centerFlexNoDecor = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`

const navTextStyle = css`
  font-weight: 400;
  color: ${(props) => props.theme.color.white};
  font-weight: 500;
  text-align: center;
  display: flex;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.color.gold};
  }
`

//Desktop Hyperlink
export const NavHyperlink = styled.a`
  ${navTextStyle};
  ${centerFlexNoDecor};
`
//Desktop Link
export const NavLink = styled(Link)`
  ${navTextStyle};
  ${centerFlexNoDecor};
`

//Navigation Bar
export const Navbar = styled.nav`
  height: 60px;
  position: fixed;
  width: 100%;
  background-color: ${(props) => (props.top ? 'transparent' : props.theme.color.blue)};
  padding: 0 1rem;
  z-index: 999;
  transition: all 500ms ease;
`

//List inside navigation bar
export const Nav = styled.ul`
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
`

//Items that disappear on mobile
export const NavContainerDesktop = styled.li`
  width: 18%;
  ${centerFlexNoDecor};
  @media ${device.deviceL} {
    display: none;
  }
`

//Logo
export const Logo = styled.img`
  padding: 5px;
  margin: 2px;
  height: 50px;
  width: 50px;
  &:hover {
    cursor: pointer;
  }
`

export const CloseModalContainer = styled.div`
  height: 20px;
`
export const CloseModalButton = styled.a`
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.8;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 4px;
    background-color: #333;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

export const NavIconsContainer = styled.li`
  ${centerFlexNoDecor};
  width: 25%;
  padding-right: 5rem;
  justify-content: flex-end;
  @media ${device.deviceL} {
    display: none;
  }
`

export const NavIcon = styled(({ ...props }) => <FontAwesomeIcon {...props} />)`
  font-size: ${(props) => props.theme.iconSize.lg};
  margin-right: 5px;
  color: ${(props) => props.theme.color.white};
  &:hover {
    color: ${(props) => props.theme.color.gold};
    cursor: pointer;
  }
`

export const FlagIcon = styled.img`
  height: 58%;
  width: auto;
  padding-left: 10px;
  cursor: pointer;
`

//Container for settings button
export const LogoContainer = styled.li`
  ${centerFlexNoDecor};
  width: 25%;
  padding-left: 5rem;
  justify-content: flex-start;
  @media ${device.deviceL} {
    width: 90%;
    padding-left: 0rem;
  }

  @media ${device.deviceM} {
    width: 60%;
  }

  @media ${device.deviceS} {
    width: 70%;
  }
`
