import { useState, useEffect } from 'react'

export const useField = (type) => {
  const [value, setValue] = useState('')

  const onChange = (event) => {
    setValue(event.target.value)
  }

  const reset = () => {
    setValue('')
  }

  return {
    params: {
      type,
      value,
      onChange,
    },
    reset,
  }
}

export const useToggle = (initialValue) => {
  const [value, setValue] = useState(initialValue)

  const toggleValue = () => setValue(!value)

  return [value, toggleValue]
}

export const useScroll = (scrollHeight) => {
  const [current, setCurrent] = useState(scrollHeight)

  useEffect(() => {
    window.onscroll = () => {
      const newCurrent = Math.ceil(window.scrollY / 100) * 100
      if (current !== newCurrent) {
        setCurrent(newCurrent)
      }
    }
  })

  return current
}

export const useCloseOnClickOutside = (ref, initialValue) => {
  const [open, setOpen] = useState(initialValue)
  const toggleOpen = (event) => {
    event.preventDefault()
    setOpen(!open)
  }
  useEffect(() => {
    const handler = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  }, [ref])

  return [open, toggleOpen]
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
