import React from 'react'

// Styles
import { Container, ListItem, ListUrl, Subtitle } from './styles'
import pdf from 'assets/content/fakturering.pdf'
// Assets
import content from 'assets/content/footer.json'

const Footer = () => {
  const linksRow = () =>
    content.other.map((info) => {
      const isFakturering = info.title.includes('Fakturering')
      return (
      <div key={info.title}>
        {info.isUrl ? (
          <ListItem>
            <p>
              {/* hack solution, since fakturering is a local document */}
              <a href={isFakturering ? pdf : info.url} target={isFakturering ? '_blank' : '_self'} rel="noreferrer">{info.title}</a>
            </p>
          </ListItem>
        ) : (
          <ListUrl>
            <p>
              {`${info.title}: `}
              <span>{`${info.info}`}</span>
            </p>
          </ListUrl>
        )}
      </div>
    )})

  return (
    <Container>
      <div>
        <Subtitle gold>Övrig information</Subtitle>
        {linksRow()}
      </div>
    </Container>
  )
}

export default Footer
