import { faFacebook, faSpotify, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { constants } from 'assets/content/index'

export { constants as constants }

export const someIcons = [
  {
    text: 'Facebook',
    icon: faFacebook,
    url: constants.url.facebook,
  },
  {
    text: 'Instagram',
    icon: faInstagram,
    url: constants.url.instagram,
  },
  {
    text: 'Spotify',
    icon: faSpotify,
    url: constants.url.spotify,
  },
  {
    text: 'Youtube',
    icon: faYoutube,
    url: constants.url.youtube,
  },
]
