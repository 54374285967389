import React from 'react'

// Styles
import { Container, ImageContainer, TextContainer, ButtonContainer, Button, Subtitle } from './styles'

const HistoryPreview = (props) => {
  const { title, image, text, showMore, setShowMore } = props

  const showMoreOrLess = () => {
    setShowMore(!showMore)
  }

  return (
    <Container>
      <TextContainer>
        <Subtitle>{title}</Subtitle>
        <div dangerouslySetInnerHTML={{ __html: text }} />
        <ButtonContainer>
          <Button onClick={() => showMoreOrLess()}>{showMore ? 'Läs mindre' : 'Läs mer'}</Button>
        </ButtonContainer>
      </TextContainer>
      <ImageContainer>{image && <img alt='' src={image}></img>}</ImageContainer>
    </Container>
  )
}

export default HistoryPreview
