import React from 'react'

// Styles
import { Select } from './styles'

// Assets
import { form as formContent } from 'assets/content/index'

const Dropdown = (props) => {
  const { selectedOption, setSelectedOption } = props

  const handleChangeSelection = (event) => setSelectedOption(event.target.value)

  return (
    <Select value={selectedOption} onChange={(event) => handleChangeSelection(event)}>
      {formContent.dropDownValues.map((value, i) => (
        <option key={i} value={value.value}>
          {value.label}
        </option>
      ))}
    </Select>
  )
}

export default Dropdown
