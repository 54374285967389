import styled from 'styled-components'
import { device } from 'services/device'
import { Button } from '../../Common/Styles/Button'

export { Button as Button }

export const Body = styled.div`
  padding: 1em;
  overflow: hidden;
`

export const Container = styled.div`
  width: 30%;
  text-align: left;
  padding: 1em 0 1em 0;
  & h3 {
    font-size: ${(props) => props.theme.fontSize.xxl};
    padding-bottom: 0.5em;
    @media ${device.deviceL} {
      font-size: ${(props) => props.theme.fontSize.lg};
    }
    &span {
      color: ${(props) => props.theme.color.gold};
      background: ${(props) => props.theme.color.gold};
    }
  }
  & p {
    color: ${(props) => props.theme.color.black};
    @media ${device.deviceL} {
      font-size: ${(props) => props.theme.fontSize.sm};
    }
  }
  & span {
    font-size: ${(props) => props.theme.fontSize.lg};
  }
  @media ${device.deviceL} {
    width: 100%;
  }
`

export const Content = styled.div`
  width: 100%;
  height: auto;
  background: ${(props) => props.theme.color.white};
  color: ${(props) => props.theme.color.blue};
  padding: 1em 0;
  transition: 3s;
  overflow: hidden;
`

export const ContainerInner = styled.div`
  background: ${(props) => props.theme.color.white};
  color: ${(props) => props.theme.color.blue};
  margin: 10px auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
`

export const ImageContainer = styled.div`
  overflow: hidden;
  & img {
    width: 100%;
    height: auto;
    overflow: hidden;
    ${ContainerInner}:hover & {
      opacity: 0.5;
      transform: scale(1.2);
      transition: 300ms ease-in-out;
    }
    @media ${device.deviceM} {
      ${ContainerInner}:hover & {
        opacity: 1;
        transform: scale(1);
        transition: none;
      }
    }
  }
`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-around;
  width: 100%;
`

export const FooterLeft = styled.div`
  display: flex;
  width: 50%;
`

export const FooterRight = styled.div`
  display: flex;
  width: 50%;
  align-items: flex-end;
  flex-direction: column;
  margin: auto 0;
  & img {
    height: 34px;
    width: auto;
  }
`
