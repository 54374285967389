import React from 'react'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

/* Components */
import Footer from './components/Footer/Footer'
import Home from './components/Home/Home'
import BackToTop from './components/Common/BackToTop'
import Navigation from './components/Navigation/Navigation'

const App = () => (
  <div>
    <Router basename='/'>
      <Navigation />
      <Switch>
        <Route exact path='/' render={() => <Home />} />
      </Switch>
      <BackToTop />
    </Router>
    <Footer />
  </div>
)

export default App
