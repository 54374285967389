import React from 'react'

// Styles
import { CarouselButtonContainer, CarouselText } from './styles'

const CarouselButtons = (props) => {
  const { activeButton, changeTopic, content } = props
  return (
    <CarouselButtonContainer>
      {content.topics.map((topic, index) => (
        <CarouselText
          key={index}
          style={{ paddingLeft: '1em' }}
          active={activeButton === index}
          disabled={activeButton === index}
          onClick={() => changeTopic(index)}
        >
          {topic.title}
        </CarouselText>
      ))}
    </CarouselButtonContainer>
  )
}

export default CarouselButtons
