const size = {
  deviceS: '480px',
  deviceM: '768px',
  deviceL: '992px',
}

export const device = {
  deviceS: `(max-width: ${size.deviceS})`,
  deviceM: `(max-width: ${size.deviceM})`,
  deviceL: `(max-width: ${size.deviceL})`,
}
