import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'
import { device } from 'services/device'
import { Title } from '../Common/Styles/Title'

export const TitleAlt = styled(Title)`
  @media ${device.deviceS} {
    font-size: ${(props) => props.theme.fontSize.xl};
  }
`

// eslint-disable-next-line
export const MediaIcon = styled(({ youtube, spotify, facebook, instagram, theme, ...props }) => <FontAwesomeIcon {...props} />)`
  font-size: ${(props) => props.theme.iconSize.xl};
  color: ${(props) => props.theme.color.white};

  padding: 0;
  ${(props) =>
    props.youtube &&
    css`
      &:hover {
        color: ${(props) => props.theme.color.youtube};
      }
    `};

  ${(props) =>
    props.facebook &&
    css`
      &:hover {
        color: ${(props) => props.theme.color.facebook};
      }
    `};

  ${(props) =>
    props.spotify &&
    css`
      &:hover {
        color: ${(props) => props.theme.color.spotify};
      }
    `};

  ${(props) =>
    props.instagram &&
    css`
      &:hover {
        color: ${(props) => props.theme.color.instagram};
      }
    `};

  @media ${device.deviceL} {
    transform: scale(0.8);
  }
  @media ${device.deviceM} {
    transform: scale(0.6);
  }
`

export const Page = styled.div`
  background-color: ${(props) => props.theme.color.blue};
  width: 100%;
  color: ${(props) => props.theme.color.white};
`

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.color.blue};
  width: 75%;
  padding: 2rem 0 3rem;
  margin: 0 auto;
  @media ${device.deviceM} {
    width: 100%;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  & p {
    font-weight: 300;
    @media ${device.deviceS} {
      font-size: ${(props) => props.theme.fontSize.sm};
    }
  }
  @media ${device.deviceM} {
    width: 75%;
  }
`

export const Container = styled.span`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
`

export const IconText = styled.span`
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSize.mlg};
  padding: 0;
  @media ${device.deviceM} {
    font-size: ${(props) => props.theme.fontSize.sm};
    display: none;
  }
`

export const Item = styled.div`
  background: ${(props) => props.theme.color.blue};
  color: ${(props) => props.theme.color.white};
  border-radius: 50%;
  border: 4px solid ${(props) => props.theme.color.blue};
  transition: all 300ms ease-in-out;
  padding-top: 20px;
  & span {
    color: ${(props) => props.theme.color.white};
  }
  & p {
    margin: 0;
    padding: 0;
    color: ${(props) => props.theme.color.white};
  }
`
